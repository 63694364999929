// Utility classes for hiding elements at specific breakpoints using "down" logic

// Define the breakpoints directly
@xs: 375px;
@sm: 576px;
@md: 768px;
@lg: 992px;
@xl: 1200px;
@xxl: 1600px;
@xxxl: 2200px;
@xxxxl: 2600px;

// Hide on extra small devices and down (less than 576px)
.hidden-xs-down {
  @media (max-width: @screen-xs-min) {
    display: none !important;
  }
}

// Hide on small devices and down (less than 768px)
.hidden-sm-down {
  @media (max-width: @screen-sm-min) {
    display: none !important;
  }
}

// Hide on medium devices and down (less than 992px)
.hidden-md-down {
  @media (max-width: @screen-md-min) {
    display: none !important;
  }
}

// Hide on large devices and down (less than 1200px)
.hidden-lg-down {
  @media (max-width: @screen-lg-min) {
    display: none !important;
  }
}

// Hide on extra large devices and down (less than 1600px)
.hidden-xl-down {
  @media (max-width: @screen-xl-min) {
    display: none !important;
  }
}

// Hide on extra extra large devices and down (1600px and above)
.hidden-xxl-down {
  @media (max-width: @screen-xxl-min) {
    display: none !important;
  }
}

// Hide on extra small devices and up
.hidden-xs-up {
  @media (min-width: @screen-xs-min) {
    display: none !important;
  }
}

// Hide on small devices and up (576px and up)
.hidden-sm-up {
  @media (min-width: @screen-sm-min) {
    display: none !important;
  }
}

// Hide on medium devices and up (768px and up)
.hidden-md-up {
  @media (min-width: @screen-md-min) {
    display: none !important;
  }
}

// Hide on large devices and up (992px and up)
.hidden-lg-up {
  @media (min-width: @screen-lg-min) {
    display: none !important;
  }
}

// Hide on extra large devices and up (1200px and up)
.hidden-xl-up {
  @media (min-width: @screen-xl-min) {
    display: none !important;
  }
}

// Hide on extra extra large devices and up (1600px and up)
.hidden-xxl-up {
  @media (min-width: @screen-xxl-min) {
    display: none !important;
  }
}

.screen(@rules, @breakpoint) {
  @media (min-width: @breakpoint) {
    @rules();
  }
}

// full-height
.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

// full-width
.w-full {
  width: 100%;
}

// 50% width
.w-50 {
  width: 50%;
}

// position relative
.relative {
  position: relative;
}



//animation for loader skeletons
@keyframes shimmer {
  0% {
    background-position: 200% 50%; /* Start from far right */
  }
  100% {
    background-position: -200% 50%; /* Move far to the left */
  }
}

// Mixin for media queries
.respond-to-xs(@content) {
  @media (min-width: @xs) {
    @content();
  }
}

.respond-to-sm(@content) {
  @media (min-width: @sm) {
    @content();
  }
}

.respond-to-md(@content) {
  @media (min-width: @md) {
    @content();
  }
}

.respond-to-lg(@content) {
  @media (min-width: @lg) {
    @content();
  }
}

.respond-to-xl(@content) {
  @media (min-width: @xl) {
    @content();
  }
}

.respond-to-xxl(@content) {
  @media (min-width: @xxl) {
    @content();
  }
}

.respond-to-xxxl(@content) {
  @media (min-width: @xxxl) {
    @content();
  }
}

.flexbox(@direction: row, @align: center, @justify: center, @wrap: nowrap, @gap: 0) {
  display: flex;
  flex-direction: @direction;
  align-items: @align;
  justify-content: @justify;
  flex-wrap: @wrap;
  gap: @gap;
}
