/* You can add global styles to this file, and also import other style files */
@import "common.less";


@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// FONT FACE
@font-face {
  font-family: 'AllRoundGothic';
  src: url('../assets/fonts/AllRoundGothic-Bold.woff2') format('woff2'),
      url('../assets/fonts//AllRoundGothic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
