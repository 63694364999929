
// Utility classes for hiding elements at specific breakpoints using "down" logic

// Hide on extra small devices and down (less than 576px)
.hidden-xs-down {
  @media (max-width: @screen-xs-min) {
    display: none !important;
  }
}

// Hide on small devices and down (less than 768px)
.hidden-sm-down {
  @media (max-width: @screen-sm-min) {
    display: none !important;
  }
}

// Hide on medium devices and down (less than 992px)
.hidden-md-down {
  @media (max-width: @screen-md-min) {
    display: none !important;
  }
}

// Hide on large devices and down (less than 1200px)
.hidden-lg-down {
  @media (max-width: @screen-lg-min) {
    display: none !important;
  }
}

// Hide on extra large devices and down (less than 1600px)
.hidden-xl-down {
  @media (max-width: @screen-xl-min) {
    display: none !important;
  }
}

// Hide on extra extra large devices and down (1600px and above)
.hidden-xxl-down {
  @media (max-width: @screen-xxl-min) {
    display: none !important;
  }
}

h1 {
  font-family: 'AllRoundGothic';
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 0;
}

h2 {
  font-family: 'Work Sans';
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0;
}

h3 {
  font-family: 'Work Sans';
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
}

body {
  font-family: 'Work Sans';
}

// Arbitarty Colors
.primary {
  color: @primary-color;
}

.seconday {
  color: @secondary-color;
}

.black {
  color: @black;
}


// Builtin Containers
.space-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

// BUTTONS
button.gamma {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @tertiary-color;

  &:hover {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @primary-color;
  }

  nz-icon {
    color: #FFF;
  }
}

button.delta {
  background-color: @secondary-color;
  border-color: @secondary-color;
  color: @primary-color;

  &:hover {
    background-color: color(~`colorPalette('@{secondary-color}', 5) `);
    border-color: color(~`colorPalette('@{secondary-color}', 5) `);
    color: @tertiary-color;
  }

  &.inverse {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @tertiary-color;
    

    &:hover {
      background-color: @secondary-color;
      border-color: @secondary-color;
      color: @primary-color;
    }
  }
}

button.epsilon {
  background-color: @quaternary-color;
  border-color: @quaternary-color;
  color: @primary-color;

  &:hover {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @tertiary-color;
  }

  &:focus {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @tertiary-color;
  }
}

button.trash {
  background-color: @quaternary-color ;
  border-color: @quaternary-color;
  color: @red-color;

  &:hover {
    background-color: @red-color;
    border-color: @red-color;
    color: @white-color;
  }

  &:focus {
    background-color: @red-color;
    border-color: @red-color;
    color: @white-color;
  }
}

// SELECT
nz-select.beta {
  &:hover {
    .ant-select-selector {
      border-color: @tertiary-color !important;
    }
  }

  .ant-select-selector {
    background-color: @secondary-color !important;
    color: @tertiary-color;
  }
}

// TAG
nz-tag.alpha {
  padding: 6px 15px;
  border-radius: 15px;
  border: 0;

  [nz-icon] {
    color: @quaternary-color;
    background: @primary-color;
    border-radius: 20px;
    padding: 5px;
    font-size: 6px;
    vertical-align: 0.125em;
    margin-left: 10px;
  }
}

// CHECKBOX
[nz-checkbox] {
  .ant-checkbox-inner {
    border-radius: 3px;
  }
}

// LABEL
.ant-form-item-label > label {
  height: 16px;
  color: @tertiary-color;
}


// MENU
.ant-dropdown-menu.alpha {
  .ant-dropdown-menu-vertical {
    .ant-dropdown-menu-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .ant-dropdown-menu-item {
    padding: 15px 20px;
    .anticon {
      font-size: 20px;
    }
  }

  .ant-menu-title-content {
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 10px;
      font-family: 'Work Sans';
    }
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.ant-menu.alpha {
  .ant-menu-vertical {
    .ant-menu-item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .ant-menu-item {
    padding: 15px 20px;
    height: auto;

    &.ant-menu-item-selected {
      .anticon {
        color: @primary-color;
      }
    }

    .anticon {
      color: @tertiary-color;
      font-size: 20px;
    }
  }

  .ant-menu-title-content {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-family: 'Work Sans';
    }
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.ant-menu {
  background-color: @white-color;
}

.ant-drawer-content {
  background-color: @white-color;
}

// ICONS
.anticon-ng-zorro\:check-unread {
  color: transparent !important;
}


// Page Container
.page-container {
  padding: 25px;
  border-radius: 8px;
  background-color: @white-color;
  margin-bottom: 20px;

  &-head {
    border-bottom: 1px solid @septenary-color;
    padding-bottom: 20px;
  }

  &-inner {
    display: flex;
    flex-direction: column;
  }
}

.detail-page {
  &-container {
  padding: 25px;
  border-radius: 8px;
  background-color: @white-color;
  margin-bottom: 20px;
  }
  &-head {
    padding-bottom: 20px;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &-info{
    margin-top: 1em;
  }
}


.ant-table-container {
  .ant-tag {
    background: @table-nz-tag-bg !important;
    margin: 5px !important;
  }
}